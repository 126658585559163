import {defineStore} from "pinia"
import {useCurrentPageRouteI18nStore} from "~/stores/current-page-route-i18n"
import type {TreeNodeData} from '~/types/TreeNodeData'
import type {ApiFetchOptions} from '~/composables/useApiFetch'

export const useNodeStore = defineStore('node', () => {
    const currentNodeId = ref<number|null>(null)
    const nodes = ref<{[key: number]: TreeNodeData}>({})

    async function loadNodeData(id: number, options: ApiFetchOptions = {}): Promise<TreeNodeData> {
        const {data, error} = await useApiFetch<TreeNodeData>(`/node/${id}`, options)

        if (error.value) {
            throw new Error('Could not load node data')
        }

        // Node data might be null if the `server` option is false.
        // We will then return a placeholder node.

        let nodeData = data.value

        if (nodeData !== null) {
            nodes.value[id] = nodeData
        } else {
            nodeData = {
                id: 0,
                name: '',
                i18n: {}
            }
        }

        return nodeData
    }

    async function getNode(id: number, andMakeCurrent = true, options: ApiFetchOptions = {}): Promise<TreeNodeData> {

        const { storeRouteI18nData } = useCurrentPageRouteI18nStore()
        let nodeData: TreeNodeData

        if (Object.keys(nodes.value).indexOf(id.toString()) === -1) {
            nodeData = await loadNodeData(id, options)
        } else {
            nodeData = nodes.value[id]
        }

        // Handling the special "placeholder" case
        if (nodeData.id > 0 && andMakeCurrent) {
            currentNodeId.value = id
            storeRouteI18nData(nodeData)
        }

        return nodeData
    }

    const currentNode = computed(() => {
        return currentNodeId.value ? nodes.value[currentNodeId.value] : null
    })

    const nodeMedias = computed(() => {
        return currentNode.value?.medias || []
    })

    return {
        currentNodeId,
        nodes,
        getNode,
        currentNode,
        nodeMedias,
    }
})